var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section__company"},[_c('div',{staticClass:"section__wrapper"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"section__subsection_blue section__company-nav"},[_c('div',{staticClass:"section__subsection-wrapper"},[_c('company-navigation')],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-with-icon title-with-icon_menu mb-5"},[_c('div',{staticClass:"title-with-icon__icon title-with-icon__icon_back"}),_c('div',{staticClass:"title-with-icon__title"},[_c('a',{attrs:{"href":"/sveden"}},[_vm._v("Сведения об образовательной организации")])]),_c('div',{staticClass:"title-with-icon__delimeter"},[_vm._v("/")]),_c('div',{staticClass:"title-with-icon__title"},[_vm._v("Стипендии и меры поддержки обучающихся")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_22 mb-1"},[_c('strong',[_vm._v("Сведения о наличии и условиях предоставления обучающимся стипендий")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice notice_red mb-2"},[_c('div',{staticClass:"content_18"},[_c('div',[_vm._v("Школа экспорта РЭЦ не предоставляет обучающимся стипендии")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_22 mb-1"},[_c('strong',[_vm._v("Сведения о наличии и условиях предоставления обучающимся мер социальной поддержки")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice notice_red mb-2"},[_c('div',{staticClass:"content_18"},[_c('div',[_vm._v("Школа экспорта РЭЦ не предоставляет обучающимся меры социальной поддержки")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_22 mb-1"},[_c('strong',[_vm._v("Сведения о наличии общежития, интерната, количестве жилых помещений в общежитии, интернате для иногородних обучающихся")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice notice_red mb-12"},[_c('div',{staticClass:"content_18"},[_c('div',[_vm._v("Школа экспорта РЭЦ не обеспечивает обучающихся общежитием, интернатом, в том числе приспособленными для использования инвалидами и лицами с ограниченными возможностями здоровья; не имеет сведений о количестве жилых помещений в общежитии, интернате для иногородних обучающихся; не имеет сведений о формировании платы за проживание в общежитии.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5 d-block d-md-none"},[_c('a',{staticClass:"history-back",attrs:{"href":"/sveden"}},[_vm._v("Вернуться")])])
}]

export { render, staticRenderFns }