<template>
  <div class="section section__company">
    <div class="section__wrapper">
    <div class="title-with-icon title-with-icon_menu mb-5">
      <div class="title-with-icon__icon title-with-icon__icon_back"></div>
      <div class="title-with-icon__title"><a href="/sveden">Сведения об образовательной организации</a></div>
      <div class="title-with-icon__delimeter">/</div>
      <div class="title-with-icon__title">Стипендии и меры поддержки обучающихся</div>
    </div>

    <div class="content_22 mb-1"><strong>Сведения о наличии и условиях предоставления обучающимся стипендий</strong></div>
    <div class="notice notice_red mb-2">
      <div class="content_18">
        <div>Школа экспорта РЭЦ не предоставляет обучающимся стипендии</div>
      </div>
    </div>
    <div class="content_22 mb-1"><strong>Сведения о наличии и условиях предоставления обучающимся мер социальной поддержки</strong></div>
    <div class="notice notice_red mb-2">
      <div class="content_18">
        <div>Школа экспорта РЭЦ не предоставляет обучающимся меры социальной поддержки</div>
      </div>
    </div>
    <div class="content_22 mb-1"><strong>Сведения о наличии общежития, интерната, количестве жилых помещений в общежитии, интернате для иногородних обучающихся</strong></div>
    <div class="notice notice_red mb-12">
      <div class="content_18">
        <div>Школа экспорта РЭЦ не обеспечивает обучающихся общежитием, интернатом, в том числе приспособленными для использования инвалидами и лицами с ограниченными возможностями здоровья; не имеет сведений о количестве жилых помещений в общежитии, интернате для иногородних обучающихся; не имеет сведений о формировании платы за проживание в общежитии.</div>
      </div>
    </div>

      <div class="my-5 d-block d-md-none">
        <a class="history-back" href="/sveden">Вернуться</a>
      </div>

      <div class="section__subsection_blue section__company-nav">
        <div class="section__subsection-wrapper">
          <company-navigation/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyNavigation from "../../components/CompanyNavigation";
export default {
  name: "InfoScholarship",
  components: {CompanyNavigation},
  mounted() {
    this.$parent.init()
  }
}
</script>